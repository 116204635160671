<template>
  <div>
    <b-row>
      <b-col
        md="9"
        lg="9"
      >
        <b-card>
          <b-row>
            <b-col
              cols="12"
              class="d-flex justify-content-end"
            >
              <b-button
                v-b-tooltip.hover="$t('addNewItemForList')"
                variant="relief-primary"
                size="sm"
              >
                <feather-icon
                  icon="ExternalLinkIcon"
                  class="clickable"
                  @click="() => {
                    addExtrnalItem()
                  }"
                />
              </b-button>
            </b-col>
          </b-row>

          <g-form @submit="save">
            <b-row class="my-0">
              <b-col
                v-if="id > 0"
                md="4"
              >
                <g-field
                  :value.sync="selectedItem.code"
                  label-text="code"
                  disabled
                />
              </b-col>
              <b-col
                v-if="currentBranch.enableEditingTransactionDates"
                md="4"
              >
                <g-picker
                  :value.sync="selectedItem.transactionDate"
                  label-text="transactionDate"
                  name="date"
                />
              </b-col>
              <b-col
                v-if="!currentBranch.enableEditingTransactionDates"
                md="4"
              >
                <g-field
                  :value="getDate(selectedItem.transactionDate)"
                  label-text="transactionDate"
                  disabled
                  name="date"
                />
              </b-col>
              <b-col md="4">
                <g-field
                  :value.sync="selectedItem.transactionTime"
                  label-text="transactionTime"
                  name="transactionTime"
                  readonly
                />
              </b-col>
              <!-- <b-col md="4">
                <g-field
                  label-text="paymentType"
                  field="select"
                  rules="required"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  :options="itemTransactionTypes"
                  :label="isRight ? 'arabicName' : 'englishName'"
                  :value.sync="selectedItem.paymentType"
                  disabled
                />
              </b-col> -->
              <b-col md="4">
                <g-field
                  :options="lookups.stores"
                  label-text="store"
                  field="select"
                  rules="required"
                  name="StoreId"
                  :value.sync="selectedItem.storeId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  :label="isRight ? 'arabicName' : 'englishName'"
                  :disabled="isClosed || hasSource || (selectedItem.lines && selectedItem.lines.length > 0)"
                />
              </b-col>
              <b-col md="4">
                <label
                  style="font-size: 14px;margin-bottom: 7px;"
                  for="supplier"
                >
                  {{ $t('supplier') }}
                  <feather-icon
                    v-b-tooltip.hover="$t('addNewSupplier')"
                    icon="ExternalLinkIcon"
                    class="clickable"
                    @click="() => {
                      addExtrnalSupplier()
                    }"
                  />
                </label>
                <g-field
                  field="select"
                  :label="isRight ? 'arabicName' : 'englishName'"
                  :disabled="isClosed || hasSource"
                  :value.sync="selectedItem.dealerId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  :options="lookups.suppliers"
                  @change="(v) => changeCustomer(v)"
                />
              </b-col>
              <!-- <b-col md="4">
                <g-field
                  label-text="agent"
                  :value.sync="selectedItem.agentId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  :options="lookups.agents"
                  field="select"
                  :label="isRight ? 'arabicName' : 'englishName'"
                  :disabled="isClosed || hasSource"
                />
              </b-col> -->
              <b-col md="4">
                <b-form-group :label="$t('closePurchOrder')">
                  <b-form-checkbox
                    v-model="selectedItem.isClosed"
                    class="custom-control-primary"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <items
                  :store-id="selectedItem.storeId"
                  :items.sync="selectedItem.lines"
                  :has-source="hasSource"
                  :transaction-type="transactionType"
                  @validate-balance="() => validate(invoiceValidationTypes.balance)"
                  @validate-salesPrice="() => validate(invoiceValidationTypes.salesPrice)"
                />
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-show="isCash"
                md="8"
              >
                <b-col
                  cols="12"
                  class="pb-2"
                >
                  <h6>
                    <strong> {{ $t('paymentWay') }} </strong>
                  </h6>
                  <b-form-radio-group
                    v-model="payemntType"
                    button-variant="outline-primary"
                    :options="optionsRadio"
                    buttons
                    size="sm"
                    name="radios-btn-default"
                  />
                </b-col>
                <b-row>
                  <b-col
                    v-if="payemntType === 'Safes'"
                    md="5"
                    class="pr-1"
                  >
                    <g-field
                      :value="isRight ? safeTransactions.safeArabicName : safeTransactions.safeEnglishName"
                      label-text="safe"
                      disabled
                    />
                  </b-col>
                  <b-col
                    v-show="payemntType !== 'Safes'"
                    md="3"
                    class="pr-1"
                  >
                    <g-field
                      :value.sync="paymentMethod.otherPaymentMethodId"
                      :dir="isRight ? 'rtl' : 'ltr'"
                      :label="isRight ? 'arabicName' : 'englishName'"
                      :options="lookups.paymentMethods"
                      label-text="paymentWay"
                      field="select"
                      @change="
                        (val) => {
                          paymentMethod.otherPaymentMethodArabicName = val.arabicName;
                          paymentMethod.otherPaymentMethodEnglishName = val.englishName;
                          bankName = val.bankId
                        }
                      "
                    />
                  </b-col>
                  <b-col
                    v-show="payemntType !== 'Safes'"
                    md="3"
                    class="pr-1"
                  >
                    <g-field
                      :value.sync="bankName"
                      :dir="isRight ? 'rtl' : 'ltr'"
                      :label="isRight ? 'arabicName' : 'englishName'"
                      :options="banks"
                      label-text="bankName"
                      field="select"
                      disabled
                    />
                  </b-col>
                  <b-col
                    v-show="payemntType !== 'Safes'"
                    md="4"
                  >
                    <g-field
                      :value.sync="paymentMethod.value"
                      type="number"
                      :rules="isCash ? `min_value:0|less_or_equal:${remaindered || 0}` : ''"
                      :disabled="remaindered == 0"
                      label-text="value"
                      name="value"
                    />
                  </b-col>
                  <b-col
                    v-show="payemntType === 'Safes'"
                    md="4"
                  >
                    <g-field
                      :value.sync="paymentMethod.cashPaid"
                      type="number"
                      label-text="value"
                      name="value"
                    />
                  </b-col>
                  <b-col
                    md="2"
                    class="d-flex align-items-center"
                  >
                    <b-button
                      v-show="payemntType !== 'Safes'"
                      variant="gradient-primary"
                      class="btn-icon"
                      :disabled="!canAddNewPayment || (payemntType === 'Safes' && !safeTransactions.safeId)"
                      @click="addNewPaymentMethod"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="payemntType === 'Safes'"
                      variant="gradient-primary"
                      class="btn-icon"
                      :disabled="payemntType === 'Safes' && !safeTransactions.safeId"
                      @click="addNewPaymentMethod"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                  </b-col>
                </b-row>
                <b-col cols="12">
                  <g-table
                    ref="items-table"
                    foot-clone
                    per-page="50"
                    small
                    :items="selectedItem.payments"
                    :columns="tableColumns"
                    :hide-options="true"
                    :no-action="true"
                    :edit-button="{
                      visiable: false,
                      icon: 'PlusSquareIcon',
                      text: 'delete',
                      handler: (item) => {
                        $emit('addNewItem', item);
                      },
                    }"
                    :delete-button="{
                      visiable: true,
                      handler: (item) => {
                        selectedItem.payments = selectedItem.payments.filter((val) => val !== item);
                      },
                    }"
                    @on-create="
                      (v) => {
                        $refs['search-modal'].show();
                      }
                    "
                  />
                </b-col>
              </b-col>
              <b-col
                md="12"
                class="mt-auto"
              >
                <b-form-group :label="$t('notes')">
                  <b-form-textarea
                    id="textarea-state"
                    v-model="selectedItem.notes"
                    maxlength="1000"
                    :state="selectedItem.notes?selectedItem.notes.length <= 1000:''"
                    label-text="Notes"
                    rows="6"
                    max-rows="6"
                  />
                  <small class="textarea-counter-value float-right"><span class="char-count">{{ selectedItem.notes?selectedItem.notes.length :0 }}</span> / 1000 </small>
                </b-form-group>
              </b-col>
            </b-row>
            <template #footer>
              <small class="text-muted">Last updated 3 mins ago</small>
            </template>
          </g-form>
          <b-modal
            ref="invoices-modal"
            no-close-on-backdrop
            cancel-variant="outline-secondary"
            centered
            size="lg"
            hide-footer
            :title="$t('allInvoice')"
          >
            <div>
              <b-col cols="12">
                <g-table
                  ref="invoice-table"
                  :items="pendingItems"
                  :columns="tableColumnsInvoice"
                  :hide-options="true"
                  :no-action="true"
                  per-page="50"
                >
                  <template #actions="{ item }">
                    <div class="text-nowrap">
                      <feather-icon
                        :id="`invoice-row-${item.id}-UploadIcon`"
                        icon="UploadIcon"
                        stroke="blue"
                        class="mx-1 clickable danger"
                        @click="retriveInvoice(item)"
                      />
                      <b-tooltip
                        :title="$t('retrive')"
                        placement="bottom"
                        :target="`invoice-row-${item.id}-UploadIcon`"
                      />
                    </div>
                  </template>
                </g-table>
              </b-col>
            </div>
          </b-modal>
        </b-card>
      </b-col>
      <!-- totalsCards -->
      <b-col
        md="3"
        lg="3"
        class="d-flex flex-column"
      >
        <b-card class="border sticky-content-top">
          <b-row class="m-auto">
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span
                class="font-weight-bolder text-nowrap"
              >
                {{ $t('total') }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="fraction(selectedItem.total)"
                placeholder="total"
                type="number"
                name="total"
                size="sm"
                disabled
              />
            </b-col>
            <!-- discount -->
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span
                class="font-weight-bolder text-nowrap"
              >
                {{ $t('discont') }}
              </span>
            </b-col>
            <b-col
              md="4"
              lg="4"
              class="pr-0"
            >
              <g-field
                :value.sync="selectedItem.discountValue"
                :disabled="!total || isLineBaseDiscount"
                size="sm"
                type="number"
                :rules="`min_value:0|max_value:${selectedItem.total}`"
                placeholder="discountValue"
                name="discountValue"
                @input="(v) =>{
                  selectedItem.discountValue < 0 || selectedItem.discountValue > selectedItem.total ? v= 0: v;
                  selectedItem.discountValue = v;
                  selectedItem.discountPercentage = 0;
                  computeDiscount({ value: v })}"
              />
            </b-col>
            <b-col
              md="5"
              lg="5"
            >
              <g-field
                :value.sync="selectedItem.discountPercentage"
                :disabled="!total || isLineBaseDiscount"
                size="sm"
                placeholder="percentage"
                name="discountPercentage"
                type="number"
                rules="min_value:0|max_value:100"
                @input="(v) => {
                  selectedItem.discountPercentage < 0 || selectedItem.discountPercentage > 100 ? v= 0: v;
                  selectedItem.discountPercentage = v;
                  computeDiscount({ Percentage :v })
                }"
              >
                <template #append>
                  <b-input-group-text> % </b-input-group-text>
                </template>
              </g-field>
            </b-col>
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span
                class="font-weight-bolder"
              >
                {{ $t('netBeforeTax') }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="fraction(selectedItem.netBeforeTaxes)"
                size="sm"
                placeholder="total"
                name="total"
                type="number"
                disabled
                readonly
              />
            </b-col>
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span
                class="font-weight-bolder"
              >
                {{ $t('tax') }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="fraction(selectedItem.totalTaxes)"
                size="sm"
                placeholder="total"
                name="total"
                type="number"
                disabled
                readonly
              />
            </b-col>
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span
                class="font-weight-bolder"
              >
                {{ $t('net') }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="fraction(selectedItem.net)"
                size="sm"
                placeholder="net"
                type="number"
                name="Net"
                readonly
              />
            </b-col>
          </b-row>
          <hr>
          <b-row
            v-if="id"
            class="m-auto"
          >
            <b-col
              md="3"
              class="p-0 d-flex mb-1 align-items-center"
            >
              <span
                class="font-weight-bolder"
              >
                {{ $t('createdBy') }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="selectedItem.createdUserName"
                size="sm"
                placeholder="createdBy"
                name="createdBy"
                readonly
              />
            </b-col>
            <b-col
              v-if="selectedItem.updatedUserName"
              md="3"
              class="p-0 d-flex mb-1 align-items-center"
            >
              <span
                class="font-weight-bolder"
              >
                {{ $t('updatedBy') }}
              </span>
            </b-col>
            <b-col
              v-if="selectedItem.updatedUserName"
              cols="12"
              md="9"
            >
              <g-field
                :value="selectedItem.updatedUserName"
                size="sm"
                placeholder="updatedBy"
                name="updatedBy"
                readonly
              />
            </b-col>
          </b-row>
        </b-card>
        <b-card class="border sticky-content-top2">
          <b-row>
            <b-button
              v-if="id || currentBranch.isMaster"
              class="mx-1 btn-block"
              variant="outline-primary"
              :disabled="selectedItem.lines.length <= 0"
              @click="print(id)"
            >
              <feather-icon
                icon="PrinterIcon"
                class="mr-50"
              />
              {{ $t('print') }}
            </b-button>
            <b-button
              v-permission="$route.meta.permission"
              class="mx-1 btn-block"
              variant="outline-primary"
              data-action-type="saveAndPrint"
              :disabled="selectedItem.lines.length <= 0"
              @click="save('saveAndPrint')"
            >
              <feather-icon
                icon="PrinterIcon"
                class="mr-50"
              />
              {{ $t('saveAndPrint') }}
            </b-button>
            <b-button
              v-permission="$route.meta.permission"
              :disabled="selectedItem.lines.length <= 0"
              data-action-type="save"
              class="mx-1 btn-block"
              variant="relief-primary"
              @click="save"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              {{ $t('save') }}
            </b-button>
            <b-button
              v-if="!selectedItem.sourceTransactionId"
              class="mx-1 btn-block"
              data-action-type="new"
              variant="outline-primary"
              @click="initObj()"
            >
              <feather-icon
                icon="RotateCwIcon"
                class="mr-50"
              />
              {{ $t('addNew') }}
            </b-button>
          </b-row>
        </b-card>
      </b-col>
      <!-- buttons -->
      <!-- <b-col md="3" class="d-flex flex-column">
        </b-col> -->
    </b-row>
  </div>
</template>

  <script>
  import invoiceMixin from '@/mixin/purchaseMixin';
  import reportMixin from '@/mixin/reportMixin';
  import GTable from '@/pages/Shared/Table.vue';
  import items from './components/items.vue';

  export default {
    components: {
      items,
      GTable
    },
    mixins: [
      invoiceMixin, reportMixin,
    ],
    props: ['id'],
    data() {
      return {
        bankName: 0,
        banks: [],
        transactionType: 'purchOrder',
        payemntType: 'Safes',
        paymentMethod: {
          value: null,
          cashPaid: 0,
          id: null,
          arabicName: '',
          englishName: '',
        },
        safeTransactions: {},
        customer: {},
        payment: {
          transactionId: 0,
          lineSerial: 0,
          value: 0,
          paymentMethod: 'safe',
          safeId: 0,
          otherPaymentMethodId: 0,
          referenceNumber: 'string',
        },
        fromTransactionType: '',
        items: [],
        errors: [],
        pendingItem: {},
        pendingItems: [],
        isCalculatingDiscount: false,
      };
    },
    computed: {
      canAddNewPayment() {
          return (this.remaindered && Number(this.paymentMethod.value) > 0 && Number(this.paymentMethod.value) <= this.remaindered)
            && ((this.payemntType === 'Safes' && this.safeTransactions) || this.paymentMethod.otherPaymentMethodId);
      },
      isCash() {
        return this.selectedItem.paymentType === 'cash';
      },
      total() {
        return this.selectedItem.lines.reduce((sum, item) => {
          sum += item.net;
          return sum;
        }, 0);
      },
      paid() {
        return this.selectedItem.payments.reduce((sum, item) => {
          sum += parseFloat(item.value);
          return sum;
        }, 0);
      },
      remaindered() {
        return this.fraction(this.selectedItem.net - this.paid) || 0;
      },
      optionsRadio() {
        return [
          { text: this.$t('safe'), value: 'Safes' },
          { text: this.$t('other'), value: 'PaymentMethods' },
        ];
      },
       tableColumnsInvoice() {
        return [
          { key: 'transactionDate', label: this.$t('transactionDate'), sortable: true },
          {
            key: 'paymentType',
            label: this.$t('paymentType'),
            sortable: true,
            isLocale: true,
          },
          {
            key: 'netBeforeTaxes',
            label: this.$t('netBeforeTax'),
            sortable: true,
          },
          {
            key: 'net',
            label: this.$t('net'),
            sortable: true,
          },
          { key: 'actions' },
        ];
      },
    },
    watch: {
      'selectedItem.paymentType'(newVal, oldVal) {
        if (newVal !== 'cash' && this.selectedItem.payments.length > 0) {
          this.selectedItem.paymentType = oldVal;
          this.confirmAction({
            text: this.$t('deletePaymentConfirmation'),
          }, () => {
            this.selectedItem.payments = [];
            this.selectedItem.paymentType = newVal;
          });
        }
      },
      'selectedItem.lines'(newVal, oldVal) {
        if (oldVal && oldVal.length > 0) this.selectedItem.payments = [];
      },
      remaindered(newVal) {
        this.paymentMethod.value = this.fraction(newVal);
        this.paymentMethod.cashPaid = this.fraction(newVal);
      },
      total(newval) {
        this.selectedItem.total = newval;
        this.computeTotals();
        if (this.selectedItem.payments.length && this.selectedItem.lines.length < 1) {
          this.selectedItem.payments = [];
        }
      },
      'selectedItem.total'(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (this.customer.discount) {
            this.computeDiscount({ Percentage: this.selectedItem.discountPercentage });
          } else {
            this.computeDiscount({ value: this.selectedItem.discountValue });
          }
        }
      },
      'selectedItem.totalTaxes'(newVal) {
        if (newVal) {
          const obj = this.lookups.suppliers.find(x => x.id === this.selectedItem.dealerId);
        if (obj && !obj.isTaxable) {
          this.selectedItem.totalTaxes = 0;
          this.selectedItem.netBeforeTaxes = this.selectedItem.total - this.selectedItem.discountValue;
          this.selectedItem.net = this.selectedItem.netBeforeTaxes;
        }
        }
      },
      'selectedItem.dealerId'(newVal) {
        if (newVal) {
        const obj = this.lookups.suppliers.find(x => x.id === this.selectedItem.dealerId);
        if (obj && !obj.isTaxable) {
          this.selectedItem.totalTaxes = 0;
          this.selectedItem.netBeforeTaxes = this.selectedItem.total - this.selectedItem.discountValue;
          this.selectedItem.net = this.selectedItem.netBeforeTaxes;
        }
      }
    }
    },
    mounted() {
      this.getBanks();
      this.updateViewModel();
      this.selectedItem.transactionType = this.transactionType;
      if (this.id > 0) {
        this.get({ url: `ItemTransactions/${this.transactionType}/${this.id}` }).then((data) => {
          this.selectedItem = data;
          data.lines.forEach((item) => {
            item.transactionId = this.selectedItem.id;
            item.units = [
              {
                unitId: item.unitId,
                unitArabicName: item.unitArabicName,
                unitEnglishName: item.unitEnglishName,
                price: item.salesPrice,
                minSalesPrice: item.minSalesPrice,
                maxSalesPrice: item.maxSalesPrice,
                barcode: item.barcode,
                isMain: true,
              },
            ];
              item.barCode = item.barcode
          });
        });
      }
    },
    methods: {
      checkForReferenceNumber(value) {
        const id = this.id ? this.id : 0;
        this.create({ url: `ItemTransactions/validate/ref-number/${id}?refNumber=${value}` })
        .then(() => {
        }).catch(() => {
         this.$swal({
          title: this.$t('Are you sure?'),
          text: this.$t('duplicatedREfNum, Do you want to continue?'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('Yes!'),
          cancelButtonText: this.$t('Cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.dismiss === 'cancel') {
            this.selectedItem.referenceNumber = '';
          }
        });
        });
      },
      addExtrnalItem() {
        var myWindow = window.open('/#/items/new', '', `width=${window.screen.availWidth}height=${window.screen.availHeight}`);
          var popupTick = setInterval(function () {
            if (myWindow.closed) {
              clearInterval(popupTick);
                // this.refreshTable()
            }
          }, 500);
      },
      addExtrnalSupplier() {
        var myWindow = window.open('/#/suppliers', '', `width=${window.screen.availWidth}height=${window.screen.availHeight}`);
          var popupTick = setInterval(function () {
            if (myWindow.closed) {
              clearInterval(popupTick);
                window.location.reload();
            }
          }, 500);
      },
      limit(event, limit) {
        if (this.selectedItem.notes.length >= limit) {
            event.preventDefault();
        }
      },
      prepareItemfromSource(sourceTransactionType) {
        this.fromTransactionType = sourceTransactionType;
        this.selectedItem.id = 0;
        this.selectedItem.transactionType = this.transactionType;
        this.selectedItem.uuid = '00000000-0000-0000-0000-000000000000';
        this.selectedItem.lines = this.selectedItem.lines.map((item) => {
           return {
            ...item,
            consumedQuantity: item.consumedQuantity === null ? 0 : item.consumedQuantity,
            originalQuantity: item.quantity,
            quantity: item.quantity - (item.consumedQuantity || 0),
          };
        });
        this.selectedItem.transactionTime = this.time;
        this.selectedItem.transactionDate = this.today;
        this.selectedItem.code = null;
        // if (sourceTransactionType === 'salesOrder') {
        //   this.selectedItem.payments = [];
        //   this.selectedItem.paymentType = 'credit';
        // }
        this.selectedItem.sourceTransactionId = this.sourceId;
      },
      togglePendingItem() {
        if (this.selectedItem.lines && this.selectedItem.lines.length > 0) {
          const pendingItem = JSON.parse(JSON.stringify(this.selectedItem));
          this.pendingItems.push(pendingItem);
          this.doneAlert({
              title: this.$t('pendingSucess'),
              type: 'success',
            });
          this.getSelecteItemObj();
        } else {
          this.doneAlert({ text: this.$t('selectItems'), type: 'warning' });
        }
      },
      retriveInvoice(item) {
        this.pendingItems = this.pendingItems.filter((v) => v !== item);
        this.selectedItem = item;
        this.$refs['invoices-modal'].hide();
      },
      changeCustomer(v) {
        if (!this.id) {
          this.selectedItem.agentId = v.agentId || null;
          this.selectedItem.onTheFlyDealerName = '';
        }
        if (!this.isLineBaseDiscount) {
          this.selectedItem.discountPercentage = v.applyDiscount && !this.isLineBaseDiscount ? v.discount : 0;
        }
        this.customer = v;
        this.selectedItem.discountPercentage = this.customer.discount;
        this.computeDiscount({ Percentage: this.selectedItem.discountPercentage })
        // this.computeDiscount({});
      },
      computeTotals() {
        // get discounted
        this.computeTotalDiscount();

        this.selectedItem.netBeforeTaxes = parseFloat(this.selectedItem.total) - parseFloat(this.selectedItem.discountValue) || 0;
        this.selectedItem.totalTaxes = 0;
        this.selectedItem.net = this.selectedItem.netBeforeTaxes;
        // compute tax values in mixin
        this.computeTotalTaxes();

        this.selectedItem.net = this.fraction(this.selectedItem.net);
        this.selectedItem.netBeforeTaxes = this.fraction(this.selectedItem.netBeforeTaxes);
        this.selectedItem.totalTaxes = Number(this.fraction(this.selectedItem.totalTaxes));
        if (this.currentBranch.taxPolicy === 'priceIncluded') {
          this.selectedItem.netBeforeTaxes = this.selectedItem.net / (1 + (this.company.taxPercentage / 100));
          this.selectedItem.totalTaxes = Number(this.selectedItem.net) - Number(this.selectedItem.netBeforeTaxes);
        }
      },
      computeDiscount({ value, Percentage }) {
        if (!this.total) return;
        if (this.isCalculatingDiscount) return;
          this.isCalculatingDiscount = true;
        value = value || 0;
        Percentage = Percentage || 0;
       if (value) {
          this.selectedItem.discountPercentage = this.fraction((value / parseFloat(this.total)) * 100);
        } else {
          this.selectedItem.discountValue = this.fraction((parseFloat(this.total) * Percentage) / 100);
        }
        // compute totals after apply discount
        this.computeTotals();
        this.isCalculatingDiscount = false;
      },
      addNewPaymentMethod() {
        if (this.paymentMethod.value) {
          this.preparePaymentModel();

          if (this.selectedItem.payments.some((p) => (p.safeId && p.safeId === this.paymentMethod.safeId) || (p.otherPaymentMethodId && p.otherPaymentMethodId === this.paymentMethod.otherPaymentMethodId))) {
            this.doneAlert({ text: this.$t('existingPayment'), type: 'error' });
          } else {
            this.paymentMethod.cashPaid = this.payemntType !== 'Safes' ? 0 : this.fraction(this.paymentMethod.cashPaid);
            if (this.paymentMethod.cashPaid < this.paymentMethod.value && this.payemntType === 'Safes') {
              this.paymentMethod.value = this.paymentMethod.cashPaid
            }
            this.selectedItem.payments.push({ ...this.paymentMethod });
          }

          this.paymentMethod = {};
        }
      },
      save(type) {
        if (this.selectedItem.lines.length <= 0) {
          this.doneAlert({ text: this.$t('selectItems'), type: 'error' });
          return;
        }
        if (!this.beforeSaveValidation()) return;
        if (!this.beforeSaveValidationDealer()) return;
        if (this.isCash && this.selectedItem.payments.length < 1 && !this.currentBranch.allowZeroItemPrice) {
          this.selectedItem.paymentType = 'credit'
        }
        if (this.errors.length > 0) {
          this.doneAlert(this.errors[0]);
          return;
        }
        // if paid amount more than needed
        if (this.remaindered < 0) {
          this.doneAlert({
            type: 'error',
            text: this.$t('paidMoreThanRequired'),
          });
          return;
        }
        const url = `ItemTransactions/${this.transactionType}`;
        (this.id > 0 ? this.update({ url, id: this.id, data: this.getModel() }).then(() => {
          if (type === 'saveAndPrint') this.print(this.id);
        })
          : this.create({ url, data: this.getModel() }))
          .then((data) => {
            // if edit or from another source go to new after save
            if (this.id > 0 || this.selectedItem.sourceTransactionId > 0) this.$router.push({ name: 'purchOrder' });
            this.doneAlert({
              title: this.$t('savedSuccessfully'),
              type: 'success',
            });
            if (type === 'saveAndPrint') this.print(data.id); this.getSelecteItemObj();
            if (this.selectedItem.id > 0) this.$router.push({ name: 'purchInvoice-list' });
             return this.getSelecteItemObj();
          });
      },
      initObj() {
        this.getSelecteItemObj();
        this.selectedItem.transactionType = this.transactionType;
      },
      getBanks() {
        this.get({ url: 'Banks' }).then((data) => {
          this.banks = data;
          this.getSelecteItemObj();
        });
      },
       print(id) {
        const printedItem = {
          id: id,
          transactionType: 7
        }
         this.printReport('SalesOrder-ar', printedItem);
      },
    },
  };
  </script>
  <style>
  .display{
    display: none;
  }
  .sticky-content-top2{
      position: sticky;
      top: calc(85px + 300px);
  }
  .sticky-bottom {
      padding: 0.8rem 1rem;
      background: transparent;
   }
  </style>
