<template>
  <div>
    <div class="my-2">
      <!-- Table Top -->
      <b-row>
        <b-col
          cols="12"
          class="pb-2"
        >
          <b-form-radio-group
            v-model="searchType"
            button-variant="outline-primary"
            :options="optionsRadio"
            buttons
            size="sm"
            name="radios-btn-default"
          />
        </b-col>
      </b-row>
      <b-row align-h="between">
        <!-- Search -->
        <b-col
          v-if="searchType === 'name'"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-start">
            <vue-autosuggest
              v-model="query"
              :suggestions="filteredOptions"
              :input-props="inputProps"
              :get-suggestion-value="getSuggestionValue"
              :limit="10"
              @selected="onSelected"
              @click="clickHandler"
              @input="onInputChange"
            >
              <template slot-scope="{suggestion}">
                <span class="my-suggestion-item">{{ suggestion.item.itemArabicName }}</span>
              </template>
            </vue-autosuggest>
          </div>
        </b-col>
        <b-col
          v-if="searchType === 'barcode'"
          cols="12"
          md="5"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="filter.barcode"
              class="d-inline-block mr-1"
              :clearable="true"
              :placeholder="$t('search')"
              @keydown.enter.prevent="getItemWithBarcode"
              @dblclick="() => $refs.searchItemsModal.openModal(transactionType)"
            />
          </div>
        </b-col>
        <b-col
          v-show="hasSource"
          md="6"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="gradient-primary"
            @click="(v) => {$refs.searchItemsModal.openModal(transactionType);}"
          >
            <feather-icon
              icon="PlusSquareIcon"
              class="mr-50"
            />
            {{ $t('addItems') }}
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="g-table"
      :items="items"
      :fields="columns"
      primary-key="barcode"
      show-empty
      hover
      small
      foot-clone
      sticky-header="100%"
      sticky-column
      :per-page="items.length"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      :sort-direction="sortDirection"
      class="mb-0"
      :empty-text="$t('noMatchingRecordsFound')"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: '180px' }"
        >
      </template>
      <template #head(actions)>
        <span />
      </template>
      <template #cell(mainUnitId)="{ item }">
        <g-field
          field="select"
          item-id="unitId"
          placeholder="unit"
          class="select-size-sm my-0"
          :value.sync="item.unitId"
          :options="item.units"
          :label="isRight ? 'unitArabicName' : 'unitEnglishName'"
          :dir="isRight ? 'rtl' : 'ltr'"
          rules="required"
          :tool-tip-error="true"
          :selectable="(option) => allowedUnits(item, option)"
          @change="(v) => {
            v.price = fraction(v.costPrice) || fraction(0);
            item.price = v.costPrice || 0;
            item.barCode = v.barcode;
            filter.barcode = '';
            computeTotal({ item });
            if (v.id) this.$emit('validate-balance');
          }"
        />
      </template>
      <template #cell(arabicName)="{ item }">
        <span style="min-width: 150px;"> {{ item.arabicName }}</span>
      </template>
      <template #cell(discount)="{ item }">
        <b-row>
          <b-col
            cols="6"
            class="p-0 pr-1"
          >
            <g-field
              :value.sync="item.discountValue"
              placeholder="value"
              type="number"
              size="sm"
              class="my-0"
              @input="
                (v) => {
                  computeTotal({
                    item: item,
                    discountValue: item.discountValue,
                  });
                }
              "
            />
          </b-col>
          <b-col
            cols="6"
            class="p-0"
          >
            <g-field
              :value.sync="item.discountPercentage"
              placeholder="%"
              type="number"
              size="sm"
              class="my-0"
              @input="
                (v) => {
                  computeTotal({
                    item: item,
                    discountPercentage: item.discountPercentage,
                  });
                }
              "
            >
              <template #append>
                <b-input-group-text> % </b-input-group-text>
              </template>
            </g-field>
          </b-col>
        </b-row>
      </template>

      <template #cell(quantity)="{ item }">
        <g-field
          :value.sync="item.quantity"
          type="number"
          rules="required|min_value:0"
          size="sm"
          class="my-0"
          :style="{ width: '100px' }"
          @input="
            (v) => {
              computeTotal({ item: item });
            }
          "
        />
      </template>

      <template #cell(price)="{ item }">
        <g-field
          :value.sync="item.price"
          type="number"
          rules="required"
          :short-desc="true"
          size="sm"
          class="my-0"
          :style="{ width: '100px' }"
          @input="
            (v) => {
              computeTotal({ item: item });
            }
          "
        />
      </template>
      <template #cell(notes)="{ item }">
        <g-field
          :value.sync="item.notes"
          :short-desc="true"
          size="sm"
          class="my-0"
        />
      </template>
      <template #cell(actions)="{ item }">
        <div class="text-nowrap">
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            variant="flat-danger"
            size="sm"
            @click="deleteItem(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="mx-0 clickable danger"
            />
          </b-button>
        </div>
      </template>
      <template
        #foot()
      >
        <span />
      </template>
      <template #foot(quantity)>
        <span> {{ fraction(totalQuantity) }} </span>
      </template>
      <template #foot(total)>
        <span> {{ fraction(totalPrices) }} </span>
      </template>
      <template #foot(discount)>
        <span> {{ fraction(totalDiscountValue) }} </span>
      </template>
      <template #foot(net)>
        <span> {{ fraction(totalNetPrice) }} </span>
      </template>
    </b-table>
    <search-items
      ref="searchItemsModal"
      :store-id="storeId"
      search-type="purchase"
      @addNewItems="addItems"
    />
  </div>
</template>

<script>
import searchItems from '@/pages/Shared/SearchItems.vue';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

export default {
  components: {
    searchItems,
    FeatherIcon,
  },
  emits: ['update:items'],
  props: {
    items: {
      type: [Array, Function],
      default: () => [],
    },
    storeId: {
      type: [String, Number, null],
      required: false,
      default: null,
    },
    hasSource: {
      type: Boolean,
      default: false,
    },
    transactionType: {
      type: [String, Number, null],
      required: false,
      default: null,
    }
  },
  data() {
    return {
      currentItems: [],
      perPage: 25,
      searchQuery: '',
      sortDirection: 'asc',
      isSortDirDesc: '',
      currentPage: 1,
      sortBy: '',
      filterOn: [],
      totalRows: 0,
      filter: {
        barcode: '',
      },
      error: {},
      totalQuantity: 0.00,
      totalPrice: 0.00,
      totalPrices: 0.00,
      totalDiscountValue: 0.00,
      totalNetPrice: 0.00,
      barcodeVal: '',
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: `${this.$t('search')}`,
      },
      datasuggest: [],
      filteredOptions: [],
      limit: 10,
      query: '',
      searchType: 'barcode'
    };
  },
  computed: {
    isLineDiscount() {
      return this.currentBranch.discountLevel === 'items';
    },
    optionsRadio() {
      return [
        { text: this.$t('barcode'), value: 'barcode' },
        { text: this.$t('name'), value: 'name' },
      ];
    },
    columns() {
      return [
        {
          key: 'lineSerial',
          label: '',
          sortable: false,
          thStyle: { width: '10px' },
        },
        {
          key: 'barCode',
          sortable: false,
          label: this.$t('barcode'),
          thStyle: { width: '10px' },
        },
        {
          key: this.isRight ? 'itemArabicName' : 'itemEnglishName',
          label: this.$t('name'),
          sortable: false,
          thStyle: { width: '300px' },
          tdClass: ['nameTd', 'td-width'],
        },
        {
          key: 'mainUnitId',
          label: this.$t('unit'),
          sortable: false,
          thStyle: { width: '200px' },
          tdClass: ['td-width'],
        },
        {
          key: 'quantity',
          label: this.$t('quantity'),
          sortable: false,
          thStyle: { width: '200px' },
        },
        {
          key: 'price',
          label: this.$t('Price'),
          sortable: false,
          thStyle: { width: '200px' },
        },
        {
          key: 'total',
          label: this.$t('total'),
          sortable: false,
          thStyle: { width: '200px' },
        },
        {
          key: 'discount',
          label: this.$t('discount'),
          sortable: false,
          thStyle: { width: '400px' },
          tdClass: ['td-width', this.isLineDiscount ? '' : 'd-none'],
          thClass: this.isLineDiscount ? '' : 'd-none',
        },
        {
          key: 'net',
          label: this.$t('net'),
          sortable: false,
          thStyle: { width: '200px' },
          tdClass: this.isLineDiscount ? '' : 'd-none',
          thClass: this.isLineDiscount ? '' : 'd-none',
        },
        {
          key: 'notes',
          label: this.$t('notes'),
          sortable: false,
          thStyle: { width: this.currentBreakPoint === 'xl' ? '30%' : '50%' },
          tdClass: this.company.showNotesInTransactionLines ? '' : 'd-none',
          thClass: this.company.showNotesInTransactionLines ? '' : 'd-none',
        },
        {
          key: 'actions',
          sortable: false,
          thStyle: { width: '200px' },
        },
      ];
    },
  },
  watch: {
    items() {
      this.getSummary();
    },
  },
  methods: {
    clickHandler() {
    },
    onSelected(option) {
      // this.query = '';
      this.addItems([option.item]);
      this.query = '';
      this.filteredOptions = [];
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.itemArabicName;
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        this.filteredOptions = [];
        return
      }
      this.get({ url: `items?arabicNamePart=${text}&WholeWord=false&storeId=${this.storeId}&pageSize=25` }).then(({ data }) => {
        var itemArr = [];
        data.forEach((item) => {
        if (!item || !item.id) return;
        const store = item.stores.find((val) => val.storeId === this.storeId) || { currentBalance: 0, costprice: 0 };
            item.currentBalance = store.currentBalance;
            item.costPrice = store.costPrice;
            item.units = item.subUnits.map((unit) => {
              return {
                ...unit,
                itemId: item.id,
                costPrice: store.costPrice * unit.conversionRate,
              };
            });
        const units = item.subUnits;
        units.push({
          unitId: item.mainUnitId,
          unitArabicName: item.mainUnitArabicName,
          unitEnglishName: item.mainUnitEnglishName,
          salesPrice: item.salesPrice,
          minSalesPrice: item.minSalesPrice,
          conversionRate: 1,
          maxSalesPrice: item.maxSalesPrice,
          barcode: item.mainUnitBarcode,
          isMain: true,
        });
        itemArr.push({
            itemArabicName: item.arabicName,
            itemEnglishName: item.englishName,
            unitId: item.matchedUnitId || item.mainUnitId,
            code: item.code,
            itemCode: item.code,
            storeId: this.storeId,
            itemId: item.id,
            quantity: 1,
            costPrice: store.costPrice,
            convertedBalance: item.currentBalance / (units.find((u) => u.unitId === item.mainUnitId).conversionRate || 1),
            price: item.matchedUnitId ? units.find((u) => u.unitId === item.matchedUnitId).salesPrice : item.salesPrice,
            mainPrice: item.matchedUnitId ? units.find((u) => u.unitId === item.matchedUnitId).salesPrice : item.salesPrice,
            discountPercentage: 0,
            discountValue: 0,
            total: 0,
            net: 0,
            stores: item.stores,
            isTaxable: item.isTaxable,
            barCode: item.matchedUnitId ? units.find((u) => u.unitId === item.matchedUnitId).barcode : item.mainUnitBarcode,
            matchedUnitId: item.matchedUnitId,
            units,
          })
        })
          if (data && data.length > 0) {
            return itemArr;
          }
      }).then((data) => {
        if (data && data.length > 0) {
          const filteredItemsData = data.filter(item => item.itemArabicName.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
          const filteredData = filteredItemsData;
          this.filteredOptions = [{
            data: filteredData,
          }]
        } else {
          this.filteredOptions = [{
            data: [],
          }]
        }
        });
    },
    addExtrnalItem() {
      var myWindow = window.open(`/#/items/new?barCode=${this.barcodeVal}`, '', `width=${window.screen.availWidth}height=${window.screen.availHeight}`);
        var popupTick = setInterval(function () {
          if (myWindow.closed) {
            clearInterval(popupTick);
              // this.refreshTable()
          }
        }, 500);
    },
    allowedUnits(item, option) {
      return !this.items.filter((i) => i !== item)
        .some((i) => i.unitId === option.unitId && i.itemId === option.itemId);
    },
    deleteItem(item) {
      const filteredItems = this.items.reduce((items, val) => {
        if (val !== item) {
          const index = items.length > 0 ? items[items.length - 1].lineSerial : 0;
          val.lineSerial = index + 1;
          items.push(val);
        }
        return items;
      }, []);
      this.$emit('update:items', filteredItems);
    },
    getItemWithBarcode() {
      if (!this.storeId || !this.filter.barcode) return;
      const code = this.filter.barcode;
      this.get({ url: `items?barcode=${this.filter.barcode}&WholeWord=true&storeId=${this.storeId}&pageSize=25` }).then(({ data }) => {
       var itemArr = [];
       if (!data.length) {
          this.barcodeVal = this.filter.barcode;
        this.confirmActionAdd(
        {
          text: this.$t('wantToAdd'),
        },
        () => {
          this.addExtrnalItem();
        }
      );
        }
        data.forEach((item) => {
        if (!item || !item.id) return;
        const store = item.stores.find((val) => val.storeId === this.storeId) || { currentBalance: 0, costprice: 0 };
        item.currentBalance = store.currentBalance;
        item.costPrice = store.costPrice;
        const units = item.subUnits.map((unit) => {
              return {
                ...unit,
                itemId: item.id,
                costPrice: store.costPrice * unit.conversionRate,
              };
            });
        units.push({
          unitId: item.mainUnitId,
          unitArabicName: item.mainUnitArabicName,
          unitEnglishName: item.mainUnitEnglishName,
          costPrice: item.costPrice,
          salesPrice: item.salesPrice,
          minSalesPrice: item.minSalesPrice,
          maxSalesPrice: item.maxSalesPrice,
          barcode: item.mainUnitBarcode,
          isMain: true,
        });
        if (item.mainUnitBarcode === code) {
          item.matchedUnitId = null
        }
        itemArr.push({
            itemArabicName: item.arabicName,
            itemEnglishName: item.englishName,
            unitId: item.matchedUnitId || item.mainUnitId,
            code: item.code,
            itemCode: item.code,
            storeId: this.storeId,
            itemId: item.id,
            quantity: 1,
            price: item.matchedUnitId ? units.find((u) => u.unitId === item.matchedUnitId).costPrice : item.costPrice,
            discountPercentage: 0,
            discountValue: 0,
            total: 0,
            net: 0,
            stores: item.stores,
            isTaxable: item.isTaxable,
            barCode: code,
            matchedUnitId: item.matchedUnitId,
            units,
          })
        })
        if (data && data.length > 0) {
          itemArr = itemArr.filter(function (_item) {
            return _item.barCode === code
          });
          this.addItems(itemArr);
        }
        this.filter.barcode = '';
      });
    },
    getSummary() {
      this.totalQuantity = 0;
      this.totalPrices = 0;
      this.totalNetPrice = 0;
      this.totalDiscountValue = 0;

      this.items.forEach((item) => {
        this.totalQuantity += parseFloat(item.quantity) || 0;
        this.totalPrices += parseFloat(item.total) || 0;
        this.totalNetPrice += parseFloat(item.net) || 0;
        this.totalDiscountValue += parseFloat(item.discountValue) || 0;
      });
    },
    computeTotal({ item, discountValue, discountPercentage }) {
      item.total = this.fraction(item.price * item.quantity);
      item.net = item.total;
      if (discountPercentage >= 0) {
        item.discountValue = (item.total * (discountPercentage / 100)).toFixed(2);
        item.net -= (item.total * discountPercentage) / 100;
      } else if (discountValue >= 0) {
        item.discountPercentage = ((discountValue / item.total) * 100).toFixed(2);
        item.net -= (discountValue || 0);
      } else {
        item.net -= item.discountValue || 0;
        item.discountPercentage = ((item.discountValue / item.total) * 100).toFixed(2);
      }
      this.getSummary();
    },
    addItems(newItems) {
      newItems.forEach((item) => {
        const existItem = this.items.find((val) => val.itemId === item.itemId && val.unitId === item.unitId);
        const itemUnit = item.units.find((unit) => unit.unitId === item.unitId);
        item.price = itemUnit.costPrice || 0;
        if (!existItem) {
          item.total = item.price * item.quantity;
          item.net = item.total;
          this.items.push({
            ...item,
            lineSerial: this.items.length + 1,
          });
        } else {
          existItem.quantity = item.quantity + Number(existItem.quantity);
          this.computeTotal({ item: existItem });
        }
        return true;
      });
      this.getSummary();
    },
    refreshTable() {
      this.$refs['g-table'].refresh();
    },
  },
};
</script>

<style lang="scss">
.v-select {
  min-width: 100px !important;
}
.form-control {
    min-width: 50px !important;
  }
  .scroll-area {
    position: static;
    width: 100%;
    height: 80%;
  }
  .nameTd {
    white-space: nowrap !important;
  }
  #autosuggest {
  width: 75% !important;
}
.autosuggest__results-container .autosuggest__results {
    background-color: #fff;
    margin-top: 1rem;
    border-radius: 0.5rem;
    -webkit-box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
    box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
    position: absolute;
    width: 71%;
    overflow-y: auto;
    max-height: 40vh;
    z-index: 999;
}
.autosuggest__results-container .autosuggest__results ul li:hover {
    background: #ededed;
    cursor: pointer;
}
.autosuggest__results-container .autosuggest__results ul li {
    list-style: none;
    padding: 0.75rem 1rem;
    cursor: pointer;
}
.autosuggest__results-container .autosuggest__results ul {
    padding-left: 0;
    margin-bottom: 0;
}
  // .form-control {
  //   min-width: 80px !important;
  // }
  // .td-width {
  //   min-width: 200px;
  // }
  .nameTd {
    white-space: nowrap !important;
  }
</style>
